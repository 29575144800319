import React from "react"
import ProjectCard from "./ProjectCard"

const Timeline = () => {
  return (
    <ol className="Timeline">
      <li className="Timeline__item">
        <span className="Timeline__item-icon"></span>
        <div className="Timeline__item-description">
          <p>
            Senior Frontend Engineer{" "}
            <a href="https://www.clara.com/es-mx/" target="_blank">
              @Clara
            </a>{" "}
            &#8226; 2023 - present
          </p>
          As a software engineer at Clara, I contribute to the development of
          our innovative corporate payment solution for companies in LATAM. I
          lead and develop features that streamline card creation and management
          processes.
        </div>
      </li>
      <li className="Timeline__item">
        <span className="Timeline__item-icon"></span>
        <div className="Timeline__item-description">
          <p>
            Senior Design Enginer{" "}
            <a href="https://www.x.com/" target="_blank">
              @Twitter
            </a>{" "}
            &#8226; 2021 - 2022
          </p>
          <ProjectCard
            title="Feather Design System"
            description="As a core team member of Twitter's internal design system, I helped built foundational patterns, UI components, tools, and documentation used by 260+ customer apps across the company.
                  Our design system would empower other teams to ship products faster, with better consistency, and with higher quality."
            imageUrl="https://res.cloudinary.com/sofia-rivas/image/upload/v1675470336/portfolio/cover-ds_wxpyuh.jpg"
            altText="Overview of the design system website home page. Including illustrations and component examples like buttons and icons."
            techStack="Javascript, React, Typescript, Testing Library."
          />
          <ProjectCard
            title="Design Tooling"
            description="As a senior UX Engineer, I was responsible for driving improvements in the design experience at Twitter through the creation of tools such as Figma plugins for the company's design systems. As part of these initiatives, I designed and prototyped an analytics tool that enabled measuring the usage and adoption of these design systems."
            imageUrl="https://res.cloudinary.com/sofia-rivas/image/upload/v1675470336/portfolio/cover-de_tpqmk2.jpg"
            altText="Overview of the design system website home page. Including illustrations and component examples like buttons and icons."
            techStack=" Node.js, Javascript, HTML, CSS,"
          />
        </div>
      </li>
      <li className="Timeline__item">
        <span className="Timeline__item-icon"></span>
        <div className="Timeline__item-description">
          <a href="https://read.cv/sofiarivas" target="_blank">
            Curious about my journey? Dive into my CV here!
          </a>
        </div>
      </li>
    </ol>
  )
}

export default Timeline
