import React from "react"

const SocialLink = ({ text, url, iconName }) => {
  return (
    <a className="home__social-link" rel="noopener" href={url} target="_blank">
      {iconName && <i className={`${iconName} home__social-icon`}></i>}
      {text}
    </a>
  )
}

export default SocialLink
