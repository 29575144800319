import React from "react"

import DefaultLayout from "../layouts/default"
import SEO from "../components/seo"
import ProjectCard from "../components/ProjectCard"
import SocialLink from "../components/SocialLink"
import Timeline from "../components/Timeline"

const IndexPage = () => {
  return (
    <DefaultLayout>
      <SEO title="Sofia Rivas - Driven by curiosity" />
      <section className="home__intro-container">
        <h1>👋🏼 Hi, I'm Sofia Rivas</h1>
        <h2>
          I am a software engineer and product designer driven by a passion for
          crafting intuitive, beautiful, and functional digital experiences.
        </h2>
      </section>
      <section>
        <div className="home__contact">
          <span className="home__contact-callout">
            🚀✨ Open to New Adventures! ✨
          </span>
          <span>
            {" "}
            I'm excited about exploring new opportunities and collaborations.
          </span>
          <p>
            If you're curious about how my skills could enhance your team or
            project, I'm all ears.{" "}
            <a href="mailto:sofi.rivasm@gmail.com">
              Let's have a chat and see where our paths may lead!{" "}
              <i class="fa-regular fa-paper-plane"></i>
            </a>
          </p>
        </div>
      </section>
      <section>
        <div className="home__divider"></div>
        <h3>Recent work 👩🏻‍💻</h3>
        <Timeline />
      </section>
      <section>
        <div className="home__divider"></div>
        <h3>Find me at:</h3>
        <div className="home__social-container">
          <SocialLink
            text="LinkedIn"
            url="https://www.linkedin.com/in/sofiarivas/"
            iconName="fa-brands fa-linkedin"
          />
          <SocialLink
            text="Github"
            url="https://github.com/sofiarivas"
            iconName="fa-brands fa-github"
          />
          <SocialLink
            text="Bento"
            url="https://bento.me/sofiarivas"
            iconName="fa-solid fa-up-right-from-square"
          />
          <SocialLink
            text="Email"
            url="mailto:sofi.rivasm@gmail.com"
            iconName="fa-solid fa-at"
          />
        </div>
      </section>
    </DefaultLayout>
  )
}

export default IndexPage
