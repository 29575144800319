import React from "react"
import PropTypes from "prop-types"

const ProjectCard = ({ title, description, imageUrl, altText, techStack }) => (
  <div className="Card__wrapper">
    <img
      className="Card__image"
      width="100%"
      height="12rem"
      src={imageUrl}
      alt={altText}
    />
    <div className="Card__description">
      <h3 className="Card__title">{title}</h3>
      <span>{description}</span>
      <code className="Card__tags">{techStack}</code>
    </div>
  </div>
)

ProjectCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
}

ProjectCard.defaultProps = {
  title: "",
  description: "",
  imageUrl: "",
}

export default ProjectCard
